import {
  Container,
  Stack,
  Flex,
  Heading,
  Text,
  Button,
  FormControl,
  FormErrorMessage,
  keyframes,
} from '@chakra-ui/react';
import { CompanyType } from '@prisma/client';

import Image from 'next/image';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import { Input } from '../../Form/Input';

import { translations } from './translations';

interface HeroSectionProps {
  title: string;
  titleGradient: string;
  subtitle: string;
  cta: string;
  imageUrl: string;
  secondaryTitle?: string;
  imageSize: {
    widthInPx: number;
    heightInPx: number;
  };
  companyType?: CompanyType;
}

export function Hero({
  cta,
  subtitle,
  title,
  titleGradient,
  imageUrl,
  imageSize,
  companyType,
}: HeroSectionProps) {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm();

  const { push, locale } = useRouter();
  const t = translations(locale);

  async function onSubmit(values: { [x: string]: string }) {
    if (companyType) {
      push(`/trial?email=${values.email}&company_type=${companyType}`);
    } else {
      push(`/trial?email=${values.email}`);
    }
  }

  const formId = 'email-book-a-call-form';

  const horizontalScroll = keyframes`
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
  `;

  const animation = `${horizontalScroll} infinite 19s linear`;

  return (
    <Container maxW={'7xl'}>
      <Stack
        align={'center'}
        spacing={{ base: 0, md: 10 }}
        py={{ base: 8, md: 8 }}
        direction={{ base: 'column', md: 'row' }}
      >
        <Stack flex={1} spacing={{ base: 5, md: 10 }}>
          <Heading
            lineHeight={1.3}
            fontWeight={600}
            fontFamily="Inter"
            fontSize={{ base: '3xl', sm: '4xl', lg: '5xl' }}
          >
            <Text as={'span'} color="gray.700" position={'relative'}>
              {title}
            </Text>
            <br />
            <Text
              bgGradient="linear(to-r, brand.blue, brand.purple)"
              bgClip="text"
              as={'span'}
            >
              {titleGradient}
            </Text>
          </Heading>

          <Text color={'gray.500'} fontSize={{ base: 20, sm: 24 }}>
            {subtitle}
          </Text>
          <Flex
            display={{ md: 'none', base: 'flex' }}
            paddingTop={{
              base: 4,
              md: 0,
            }}
            paddingBottom={{
              base: 4,
              md: 0,
            }}
            justify={'center'}
            align={'center'}
            position={'relative'}
            w={'full'}
          >
            <Image
              alt={'Hero Image'}
              objectFit={'cover'}
              width={`${imageSize.widthInPx} px`}
              height={`${imageSize.heightInPx} px`}
              src={imageUrl}
            />
          </Flex>
          <form id={formId} onSubmit={handleSubmit(onSubmit)}>
            <Stack
              py={4}
              maxW="450px"
              spacing={{ base: 4, sm: 2 }}
              display="flex"
              direction={'column'}
            >
              <Flex flexDirection={{ base: 'column', sm: 'row' }} gap={4}>
                <FormControl isInvalid={!!errors.email}>
                  <Input
                    placeholder={t.emailPlaceholder}
                    maxW={{ base: '100%', md: '300px' }}
                    {...register('email', {
                      pattern: /\S+@\S+\.\S+/,
                      required: t.emailErrorMessage,
                    })}
                  />
                  <FormErrorMessage>
                    {errors.email && t.emailErrorMessage}
                  </FormErrorMessage>
                </FormControl>

                <Button
                  rounded={'md'}
                  size={'lg'}
                  fontWeight={'600'}
                  fontSize={20}
                  px={10}
                  color="#fff"
                  bgGradient="linear(to-r, brand.blue, brand.purple)"
                  _hover={{
                    opacity: '0.8',
                    transition: '0.2s',
                  }}
                  type="submit"
                  form={formId}
                  isLoading={isSubmitting}
                >
                  {cta}
                </Button>
              </Flex>
            </Stack>
          </form>
        </Stack>

        <Flex
          flex={1}
          display={{ md: 'flex', base: 'none' }}
          paddingTop={{
            base: 4,
            md: 0,
          }}
          paddingBottom={{
            base: 4,
            md: 0,
          }}
          justify={'center'}
          align={'center'}
          position={'relative'}
          w={'full'}
          transition={'all ease 200ms'}
          _hover={{
            transform: 'scale(1.03)',
          }}
        >
          <Image
            alt={'Hero Image'}
            priority={true}
            objectFit={'cover'}
            width={`${imageSize.widthInPx} px`}
            height={`${imageSize.heightInPx} px`}
            src={imageUrl}
          />
        </Flex>
      </Stack>

      <Flex
        align={'center'}
        justifyContent="space-between"
        rowGap={10}
        mb={20}
        pt={{ base: 10, md: 12 }}
        direction={'row'}
        flexWrap="nowrap"
        display={{
          md: 'flex',
          base: 'none',
        }}
        animation={animation}
      >
        <img
          alt="FG logo"
          src="/agencies-logos/fg.png"
          height="64px"
          width="180px"
          style={{ marginRight: 32 }}
        />
        <img
          alt="Agência E-plus Logo"
          src="/agencies-logos/eplus.png"
          height="64px"
          width="180px"
          style={{ marginRight: 32 }}
        />
        <img
          alt="CommerceGrowth logo"
          src="/agencies-logos/commerce-growth.png"
          height="64px"
          width="180px"
          style={{ marginRight: 32 }}
        />
        <img
          alt="Avantage Midia Logo"
          src="/agencies-logos/avantage-midia.jpeg"
          height="64px"
          width="90px"
          style={{ marginRight: 32 }}
        />
        <img
          alt="Etraction logo"
          src="/agencies-logos/etraction.png"
          height="64px"
          width="180px"
          style={{ marginRight: 32 }}
        />
        <img
          alt="Genesis ads Logo"
          src="/agencies-logos/genesis.png"
          height="64px"
          width="180px"
          style={{ marginRight: 32 }}
        />
        <img
          alt="Qg digital logo"
          src="/agencies-logos/qg.png"
          height="64px"
          width="180px"
          style={{ marginRight: 32 }}
        />
        <img
          alt="Wave logo"
          src="/agencies-logos/wave.png"
          height="64px"
          width="180px"
          style={{ marginRight: 32 }}
        />
        <img
          alt="EConverse logo"
          src="/agencies-logos/econverse.png"
          height="64px"
          width="180px"
          style={{ marginRight: 32 }}
        />
        <img
          alt="Rg logo"
          src="/agencies-logos/rg.png"
          height="64px"
          width="180px"
          style={{ marginRight: 32 }}
        />
        <img
          alt="Way logo"
          src="/agencies-logos/way-ecommerce.png"
          height="64px"
          width="100px"
          style={{ marginRight: 32 }}
        />

        <>
          <img
            alt="FG logo"
            src="/agencies-logos/fg.png"
            height="64px"
            width="180px"
            style={{ marginRight: 32 }}
          />
          <img
            alt="Agência E-plus Logo"
            src="/agencies-logos/eplus.png"
            height="64px"
            width="180px"
            style={{ marginRight: 32 }}
          />
          <img
            alt="CommerceGrowth logo"
            src="/agencies-logos/commerce-growth.png"
            height="64px"
            width="180px"
            style={{ marginRight: 32 }}
          />
          <img
            alt="Avantage Midia Logo"
            src="/agencies-logos/avantage-midia.jpeg"
            height="64px"
            width="90px"
            style={{ marginRight: 32 }}
          />
          <img
            alt="Etraction logo"
            src="/agencies-logos/etraction.png"
            height="64px"
            width="180px"
            style={{ marginRight: 32 }}
          />
          <img
            alt="Genesis ads Logo"
            src="/agencies-logos/genesis.png"
            height="64px"
            width="180px"
            style={{ marginRight: 32 }}
          />
          <img
            alt="Qg digital logo"
            src="/agencies-logos/qg.png"
            height="64px"
            width="180px"
            style={{ marginRight: 32 }}
          />
          <img
            alt="Wave logo"
            src="/agencies-logos/wave.png"
            height="64px"
            width="180px"
            style={{ marginRight: 32 }}
          />
          <img
            alt="EConverse logo"
            src="/agencies-logos/econverse.png"
            height="64px"
            width="180px"
            style={{ marginRight: 32 }}
          />
          <img
            alt="Rg logo"
            src="/agencies-logos/rg.png"
            height="64px"
            width="180px"
            style={{ marginRight: 32 }}
          />
          <img
            alt="Way logo"
            src="/agencies-logos/way-ecommerce.png"
            height="64px"
            width="100px"
            style={{ marginRight: 32 }}
          />
        </>
        <>
          <img
            alt="FG logo"
            src="/agencies-logos/fg.png"
            height="64px"
            width="180px"
            style={{ marginRight: 32 }}
          />
          <img
            alt="Agência E-plus Logo"
            src="/agencies-logos/eplus.png"
            height="64px"
            width="180px"
            style={{ marginRight: 32 }}
          />
          <img
            alt="CommerceGrowth logo"
            src="/agencies-logos/commerce-growth.png"
            height="64px"
            width="180px"
            style={{ marginRight: 32 }}
          />
          <img
            alt="Avantage Midia Logo"
            src="/agencies-logos/avantage-midia.jpeg"
            height="64px"
            width="90px"
            style={{ marginRight: 32 }}
          />
          <img
            alt="Etraction logo"
            src="/agencies-logos/etraction.png"
            height="64px"
            width="180px"
            style={{ marginRight: 32 }}
          />
          <img
            alt="Genesis ads Logo"
            src="/agencies-logos/genesis.png"
            height="64px"
            width="180px"
            style={{ marginRight: 32 }}
          />
          <img
            alt="Qg digital logo"
            src="/agencies-logos/qg.png"
            height="64px"
            width="180px"
            style={{ marginRight: 32 }}
          />
          <img
            alt="Wave logo"
            src="/agencies-logos/wave.png"
            height="64px"
            width="180px"
            style={{ marginRight: 32 }}
          />
          <img
            alt="EConverse logo"
            src="/agencies-logos/econverse.png"
            height="64px"
            width="180px"
            style={{ marginRight: 32 }}
          />
          <img
            alt="Rg logo"
            src="/agencies-logos/rg.png"
            height="64px"
            width="180px"
            style={{ marginRight: 32 }}
          />
          <img
            alt="Way logo"
            src="/agencies-logos/way-ecommerce.png"
            height="64px"
            width="100px"
            style={{ marginRight: 32 }}
          />
        </>
      </Flex>

      <Flex
        align={'center'}
        justifyContent="space-between"
        rowGap={10}
        mb={20}
        pt={{ base: 10, md: 12 }}
        direction={{ base: 'column', md: 'row' }}
        flexWrap="wrap"
        display={{
          base: 'flex',
          md: 'none',
        }}
      >
        <img
          alt="FG logo"
          src="/agencies-logos/fg.png"
          height="64px"
          width="180px"
          style={{ marginRight: 32 }}
        />
        <img
          alt="Agência E-plus Logo"
          src="/agencies-logos/eplus.png"
          height="64px"
          width="180px"
          style={{ marginRight: 32 }}
        />
        <img
          alt="CommerceGrowth logo"
          src="/agencies-logos/commerce-growth.png"
          height="64px"
          width="180px"
          style={{ marginRight: 32 }}
        />
        <img
          alt="Avantage Midia Logo"
          src="/agencies-logos/avantage-midia.jpeg"
          height="64px"
          width="90px"
          style={{ marginRight: 32 }}
        />
        <img
          alt="Etraction logo"
          src="/agencies-logos/etraction.png"
          height="64px"
          width="180px"
          style={{ marginRight: 32 }}
        />
        <img
          alt="Genesis ads Logo"
          src="/agencies-logos/genesis.png"
          height="64px"
          width="180px"
          style={{ marginRight: 32 }}
        />
        <img
          alt="Qg digital logo"
          src="/agencies-logos/qg.png"
          height="64px"
          width="180px"
          style={{ marginRight: 32 }}
        />
        <img
          alt="Wave logo"
          src="/agencies-logos/wave.png"
          height="64px"
          width="180px"
          style={{ marginRight: 32 }}
        />
        <img
          alt="EConverse logo"
          src="/agencies-logos/econverse.png"
          height="64px"
          width="180px"
          style={{ marginRight: 32 }}
        />
        <img
          alt="Rg logo"
          src="/agencies-logos/rg.png"
          height="64px"
          width="180px"
          style={{ marginRight: 32 }}
        />
      </Flex>
    </Container>
  );
}
